<template>
  <div class="piece-box inner-content department-equipment ">
    <div class="piece-box-title flex-box align-center">
      <div class="flex-grow">部门设备</div>
    </div>
    <div class="department-equipment-table">
      <div class="department-equipment-row department-equipment-head department-equipment-deep">
        <div class="department-equipment-td">设备名称</div>
        <div class="department-equipment-td">保管人</div>
      </div>
      <div class="department-equipment-body" v-if="list.length > 0">
        <div class="department-equipment-row" :class="{'department-equipment -deep': i % 2 == 1}" v-for="(item, i) in list" :key="i">
          <div class="department-equipment-td">{{ item.device + "(" + item.asset_code + ")" }}</div>
          <div class="department-equipment-td">{{ item.manager }}</div>
        </div>
      </div>
      <a-empty :image="emptyImage" v-else />
    </div>
  </div>
</template>

<script>
    import { Empty } from 'ant-design-vue';

    export default {
        name: "DepartmentEquipment",
        props: {
            number: Array
        },
        data() {
            return {
                type: 1,
                emptyImage: Empty.PRESENTED_IMAGE_SIMPLE
            }
        },
        computed: {
            list() {
                let res = [];
                if(this.number) {
                    res = [...this.number];
                }
                return res;
            }
        }
    }
</script>

<style lang="less">
.department-equipment-table {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  height: 249px;
}
.department-equipment-body {
  flex: 1;
  min-height: 0;
  overflow: auto;
}
.department-equipment-row {
  display: flex;
  align-items: center;
  text-align: center;
  height: 36px;
  &.department-equipment-head {
    color: rgba(0,0,0,0.85);
    font-weight: bold;
  }
  &.department-equipment-deep {
    background-color: @background-color-base;
  }
}
.department-equipment-td {
  flex: 1;
}
</style>
